//
// Main Javascript file
// Author: Graffino (http://www.graffino.com)
//

/**
 *  Global Vars
 */
 // Linting exceptions
 /* global FontFaceObserver */
 
// Global
var fontFaceObserverName         = 'OpenSans';
var $viewport                    = $('html, body');
var $window                      = $(window);
var windowHeight                 = $window.height();
var windowHeightPadded           = windowHeight / 1.2;
var $main                        = $('.main');
var mainStateClass               = 'has-floating';

// Scroll
var scrollPoolingDelay           = 250;
var scrollEvent                  = false;

// Parallax
var $parallax                    = $('.js-parallax');

// Header
var headerStateClass             = 'is-floating';
var headerStateNavClass          = 'has-open-menu';
var $header                      = $('.js-header');

// Nav
var $nav                         = $('.js-nav');
var $navAction                   = $('.js-nav-action');
var navStateClass                = 'is-visible';

// Map
var $map                         = $('.js-map');
var $mapMarker                   = $('.js-map-marker');

// Expand
var expand                       = '.js-expand';
var $expandAction                = $('.js-expand-action');
var expandStateClass             = 'is-expanded';

// Textarea
var $textarea                    = $('.js-textarea');

// Contact
var $contact                     = $('.js-contact');
var $contactContent              = $('.js-contact-content');
var $contactNotice               = $('.js-contact-notice');

// Animations
var animationTriggerClass        = 'js-animate-on-scroll';
var animationDoneClass           = 'is-animated';
var animationOnStartTriggerClass = 'js-animate-on-start';
var animationGroupTriggerClass   = 'js-animate-group';
var animationGroupPartClass      = 'js-animate-group-part';

/**
 *  HTML Animation data-attributes
 *
 *  data-animation='animation-name'
 *  data-timeout='animation-timeout in ms'
 *
 */

/**
 * [graffino Object]
 * @type {Object}
 */
var graffino = {

    init: function() {

        // Links actions
        graffino.linksHandler();

        // Fonts hander
        graffino.fontsHandler();

        // Detect browsers
        graffino.detectBrowser();

        // Scroll events
        graffino.scrollHandler();

        // Resize events
        graffino.resizeHandler();

        // Animation handler
        graffino.animationHandler();

        // Parallax handler
        graffino.parallaxHandler();

        // Expand handler
        graffino.expandHandler();

        // Navigation handler
        graffino.navHandler();

        // Textarea handler
        graffino.textareaHandler();

        // Contact handler
        graffino.contactHandler();

        // Map handler
        graffino.mapHandler();

    },

    // Links handler
    linksHandler: function() {
        // Initialize function
        function __init () {
            // Open in new window links with rel=external code
            $('a[rel="external"]').attr('target','_blank');
            // Prevent default action on # (hash) links
            $('a[href="#"]').on('click', function(e) { e.preventDefault(); });
        }

        // Initialize module
        return __init();
    },

    // Fonts handler
    fontsHandler: function() {
        // Initialize function
        function __init() {
            var observer = new FontFaceObserver(fontFaceObserverName);
            // Add fonts-class when fonts are loaded
            observer.check().then( function() {
                document.documentElement.className += ' fonts-loaded';
            });
        }

        // Initialize module
        return __init();
    },

    // Detect browsers
    detectBrowser: function() {
        // Initialize function
        function __init() {
            var isIE = detectIE();
            // Add class to HTML element
            if (isIE) { $('html').addClass('ie '+isIE); }
        }

        // Detect IE
        function detectIE() {
            var ua      = window.navigator.userAgent;
            var msie    = ua.indexOf('MSIE ');
            var trident = ua.indexOf('Trident/');

            if (msie > 0) {
                // IE 10 or older => return version number
                return 'ie'+parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
            }

            if (trident > 0) {
                // IE 11 (or newer) => return version number
                var rv = ua.indexOf('rv:');
                return 'ie'+parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
            }
            // Other browser
            return false;
        }

        // Initialize module
        return __init();
    },

    // Scroll handler
    scrollHandler: function() {
        // Initialize function
        function __init() {
            // Check for scroll function
            $(window).scroll(function() {
                scrollEvent = true;

                // Clear Timeout
                clearTimeout($.data(this, 'scrollTimer'));

                $.data(this, 'scrollTimer', setTimeout(function() {

                    // Fire after scroll stopped for 250ms
                    scrollStopped();

                }, scrollPoolingDelay));

                // Fire instantly (performance issue)
                scrollInstantly();
            });

            // Fire on scroll in 250ms intervals
            setInterval (function() {
                if (scrollEvent) {

                    scrollThrottled();

                    // Reset scroll count
                    scrollEvent = false;
                }
            }, scrollPoolingDelay);
        }

        // Fire after scroll stopped for 250ms
        function scrollStopped() {
            // Manage animations
            graffino.animationHandler('animateOnScroll');
            graffino.animationHandler('animateByGroup');
        }

        // Fire instantly (performance issue)
        function scrollInstantly() {
            // Parallax handler
            graffino.parallaxHandler();
        }

        // Fire on scroll in 250ms intervals
        function scrollThrottled() {
            // Nav visibility
            graffino.navHandler('navVisibility');

            // Nav close
            graffino.navHandler('navClose');
        }

        // Initialize module
        return __init();
    },

    // Resize handler
    resizeHandler: function() {
        // Initialize function
        function __init() {
            $(window).on('resize', function() {
                // Do stuff
            });
        }

        // Initialize module
        return __init();
    },

    // Animation handler
    animationHandler: function(action) {
        // Set variable to false if not defined
        action = action || null;

        // Initialize function
        function __init() {
            switch (action) {
                case 'animateOnScroll':
                    // Animate on scroll
                    animateOnScroll();
                break;
                case 'animateOnLoad':
                    // Animate on load
                    animateOnLoad();
                break;
                case 'animateByGroup':
                    // Animate by group
                    animateByGroup();
                break;
                default:
                // Animate stop
                animateStop();
                // Animate on load
                animateOnLoad();
                break;
            }
        }

        // Animate on scroll
        function animateOnLoad() {
            var $notAnimated = $('.' + animationOnStartTriggerClass + ':not(".' + animationDoneClass + '")');

            // If items not animated
            $notAnimated.each(function () {
                var $this = $(this),
                    animationTimeout = parseInt($this.data('timeout'),10),
                    animationName = '-animate-' + $this.data('animation');

                    if (animationTimeout) {
                        setTimeout(function(){
                            $this.addClass(animationDoneClass + ' ' + animationName);
                        }, animationTimeout);
                    }
            });
        }

        // Animate on scroll
        function animateOnScroll() {
            var hasScrolled = $window.scrollTop();
            var $notAnimated = $('.' + animationTriggerClass + ':not(".'+ animationDoneClass + '")');
            var $animated = $('.' + animationTriggerClass + '.'+ animationDoneClass);

            // If items not animated
            $notAnimated.each(function () {
                var $this = $(this),
                    animationOffset = $this.offset().top,
                    animationTimeout = parseInt($this.data('timeout'),10),
                    animationName = '-animate-' + $this.data('animation');

                if (hasScrolled + windowHeightPadded > animationOffset) {
                    if (animationTimeout) {
                        setTimeout(function(){
                            $this.addClass(animationDoneClass + ' ' + animationName);
                        }, animationTimeout);
                    } else {
                        $this.addClass(animationDoneClass + ' ' + animationName);
                    }
                } else {
                    $this.removeClass(animationDoneClass);
                    $this.removeClass(animationName);
                }
            });

            // If items are animated and scrolled out of view
            $animated.each(function () {
                var $this = $(this);
                var animationOffset = $this.offset().top;
                var animationName = '-animate-' + $this.data('animation');

                if (hasScrolled + windowHeightPadded < animationOffset || hasScrolled + windowHeightPadded > animationOffset + windowHeight) {
                    $this.removeClass(animationDoneClass);
                    $this.removeClass(animationName);
                }
            });
        }

        // Animate by group
        function animateByGroup() {
            var hasScrolled = $window.scrollTop();
            var $groupNotAnimated = $('.' + animationGroupTriggerClass + ':not(".'+ animationDoneClass +'")');
            var $groupAnimated = $('.' + animationGroupTriggerClass + '.'+ animationDoneClass);

            // If items not animated
            $groupNotAnimated.each(function () {
                var $thisParent = $(this);
                var parentOffset = $thisParent.offset().top;
                var $groupAnimationPart = $thisParent.find('.' + animationGroupPartClass);

                if (hasScrolled + windowHeightPadded > parentOffset ) {

                    $groupAnimationPart.each(function() {
                        var $thisChild = $(this);
                        var animationTimeout = parseInt($thisChild.data('timeout'),10);
                        var animationName = '-animate-' + $thisChild.data('animation');

                        if (animationTimeout) {
                            setTimeout(function(){
                                $thisChild.addClass(animationName);
                                $thisParent.addClass(animationDoneClass);
                            }, animationTimeout);
                        } else {
                            $thisChild.addClass(animationName);
                            $thisParent.addClass(animationDoneClass);
                        }

                    });

                } else {
                    $groupAnimationPart.each(function() {
                        var $thisChild = $(this);
                        var animationName = '-animate-' + $thisChild.data('animation');

                        $thisChild.removeClass(animationName);
                    });
                    $thisParent.removeClass(animationDoneClass);
                }
            });

            // If items are animated and scrolled out of view
            $groupAnimated.each(function () {
                var $thisParent = $(this),
                    animationOffset = $thisParent.offset().top,
                    $groupAnimationPart = $thisParent.find('.' + animationGroupPartClass);

                if (hasScrolled + windowHeightPadded < animationOffset || hasScrolled + windowHeightPadded > animationOffset + windowHeight) {
                    $thisParent.removeClass(animationDoneClass);
                    $groupAnimationPart.each(function() {
                        var $thisChild = $(this),
                            animationName = '-animate-' + $thisChild.data('animation');

                        $thisChild.removeClass(animationName);
                    });
                }
            });
        }

        // Animate stop
        function animateStop() {
            $viewport.on('scroll mousedown DOMMouseScroll mousewheel keyup', function(e){
                if ( e.which > 0 || e.type === 'mousedown' || e.type === 'mousewheel'){
                    $viewport.stop();
                }
            });
        }

        // Initialize module
        return __init();
    },

    // Parallax handler
    parallaxHandler: function() {
        // Initialize function
        function __init() {
            if ($parallax.length > 0) {
                $parallax.each(function() {
                    var dataType = $(this).data('type');
                    var yPosition = -($window.scrollTop() / $(this).data('speed'));
                    var coordinateAdjust = $(this).data('adjust');
                    var coordinatesXY = '50% ' + yPosition + 'px';
                    var coordinatesY = Math.abs(yPosition) - coordinateAdjust + 'px';


                    if (dataType !== 'background') {
                        // Move the item on Y
                        $(this).css({ 'top': coordinatesY });
                    } else {
                        // Move the background
                        $(this).css({ 'background-position': coordinatesXY });

                    }
                });
            }
        }
        // Initialize module
        return __init();
    },

    // Expand handler
    expandHandler: function() {
        // Initialize function
        function __init() {
            if ($expandAction.length > 0) {
                $($expandAction).on('click', function() {
                    $(this).closest(expand).toggleClass(expandStateClass);
                });
            }
        }
        // Initialize module
        return __init();
    },

    // Navigation handler
    navHandler: function(action) {
        // Set variable to false if not defined
        action = action || null;

        // Initialize function
        function __init() {
            switch (action) {
                case 'navVisibilty':
                    // Nav visibility
                    navVisibilty();
                break;
                case 'navActions':
                    // Nav actions
                    navActions();
                break;
                case 'navClose':
                    // Nav close
                    navClose();
                break;
                case 'navOpen':
                    // Nav close
                    navOpen();
                break;
                default:
                // Nav actions
                navActions();
                // Nav visibility
                navVisibilty();
            }
        }
        // Nav visibility
        function navVisibilty() {
            if ($header.length > 0 ) {
                var currentPosition = $window.scrollTop();
                var headerHeight = $header.outerHeight();

                if (currentPosition > headerHeight) {
                    $header.addClass(headerStateClass);
                    $main.addClass(mainStateClass);
                } else {
                    $header.removeClass(headerStateClass);
                    $main.removeClass(mainStateClass);
                }
            }
        }
        // Nav actions
        function navActions() {
            $navAction.unbind('click').on('click', function(e) {
                e.preventDefault();
                if ($nav.length > 0) {
                    if ($header.hasClass(headerStateNavClass)) {
                        navClose();
                    } else {
                        navOpen();
                    }
                }
            });
        }
        // Nav open
        function navOpen() {
            if ($nav.length > 0) {
                $nav.addClass(navStateClass);
                $header.addClass(headerStateNavClass);
            }
        }
        // Nav close
        function navClose() {
            if ($nav.length > 0) {
                $nav.removeClass(navStateClass);
                $header.removeClass(headerStateNavClass);
            }
        }

        // Initialize module
        return __init();
    },

    // Textarea hander
    textareaHandler: function() {

        // Initialize Module
        function __init() {
            if ($textarea.length > 0) {
                $textarea.each(function () {
                    $(this).css({'height':'auto','overflow-y':'hidden'}).height(this.scrollHeight);
                }).on('input', function () {
                    $(this).css({'height':'auto','overflow-y':'hidden'}).height(this.scrollHeight);
                });
            }
        }

        // Initialize Module
        return __init();
    },

    // Contact handler
    contactHandler: function() {
        // Initialize function
        function __init() {
            if ($contact.length > 0) {
                // Vars
                var contactHeight = $contact.outerHeight();

                // Validate contact form
                $contact.h5Validate();

                // Process contact form
                $contact.submit(function(e) {
                    // Vars
                    var result = $contact.h5Validate('allValid');
                    var data;
                    var url;
                    var method;

                    if ( result === true ) {
                        // Serialize contact data
                        data = $(this).serialize();
                        // Get URL from action
                        url = $(this).attr('action');
                        method = $(this).attr('method');

                        // Send request
                        $.ajax({
                            url         : url,
                            data        : data,
                            type        : method,
                            cache       : false,
                            error       : function(result) {
                                // Log message
                                console.log(result);
                            },
                            success     : function(result) {
                                result = result || null;
                                // Log message
                                // console.log(result);
                                // Prevent resizing
                                $contact.css({'min-height': contactHeight + 'px'});
                                // Hide form content for 10s
                                $contactContent.velocity('fadeOut', { duration: 800 })
                                               .velocity('fadeIn', { delay: 10000, duration: 1000 });
                                // Show form notice for 9s
                                $contactNotice.velocity('fadeIn', { delay: 850, duration: 1500 })
                                               .velocity('fadeOut', { delay: 7000, duration: 800 });
                                // Clear fields
                                $contact.trigger('reset');
                            }
                        });
                    }
                    // Prevent actual form submit
                    e.preventDefault();
                });
            }
        }

        // Initialize module
        return __init();
    },

    // Map handler
    mapHandler: function () {
        // Initialize Module
        function __init() {
            // If we have $map in the DOM
            if ($map.length > 0) {
                // Render default map
                $mapMarker.each(function(i) {
                    var $marker = $(this);
                    var topPosition = $marker.data('top');
                    var leftPosition = $marker.data('left');
                    var transitionDelay = i/10 + 's';

                    var styles = {
                        'top': topPosition,
                        'left': leftPosition,
                        'visibility': 'visible',
                        'opacity': '1',
                        'transition-delay': transitionDelay,
                        '--webkit-transition-delay': transitionDelay,
                        '--moz-transition-delay': transitionDelay
                    };
                    $marker.css(styles);
                });
            }
        }

        // Initialize Module
        return __init();
    }

};

/**
 * Document ready (loaded)
 */
jQuery(document).ready(function() {
    // Init scripts
    graffino.init();
});

/**
 *  Document load (in process of loading)
 */
jQuery(window).load(function() {
    // Do stuff
});
